import { AnyAction } from 'redux'
import { RegistrationData } from '../types'
import {
  CLEAR_PA_API_MUNICIPALITIES,
  RECEIVE_PA_API_MUNICIPALITIES,
  RECEIVE_PA_API_SETUP,
  REQUEST_PA_API_SETUP,
  RECEIVE_PA_API_FORM_SUBMIT,
  RECEIVE_PA_API_POLLING,
  ERROR_PA_API_FORM_SUBMIT,
  POLL_PA_API,
  SAVE_PA_API_FORM_LOCALLY,
} from '../actions/constants'
import { mapDescriptionFieldsToSimpleList } from '../utils/paSetupUtils'

// Define the initial state
const initialState: RegistrationData = {
  isFetching: false,
  paAPISetup: {
    assistanceTypeOptions: [],
    countyOptions: [],
    municipalityOptions: [],
    raceOptions: [],
    sexOptions: [],
    suffixOptions: [],
    unitTypeOptions: [],
    politicalPartyOptions: [],
  },
  pollCount: 0,
}

// Define the reducer function
const registrationReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    // Add your action types and corresponding logic here
    case CLEAR_PA_API_MUNICIPALITIES:
      return {
        ...state,
        paAPISetup: {
          ...state.paAPISetup,
          municipalityOptions: [],
        },
      }
    case REQUEST_PA_API_SETUP:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_PA_API_MUNICIPALITIES:
      return {
        ...state,
        isFetching: false,
        paAPISetup: {
          ...state.paAPISetup,
          municipalityOptions: mapDescriptionFieldsToSimpleList(
            'MunicipalityIDname',
            action.payload,
          ),
        },
      }
    case RECEIVE_PA_API_SETUP:
      const payload = action.payload
      return {
        ...state,
        isFetching: false,
        paAPISetup: {
          ...state.paAPISetup,
          sexOptions: mapDescriptionFieldsToSimpleList(
            'GenderDescription',
            payload.Gender,
          ),
          suffixOptions: mapDescriptionFieldsToSimpleList(
            'NameSuffixDescription',
            payload.Suffix,
          ),
          raceOptions: mapDescriptionFieldsToSimpleList(
            'RaceDescription',
            payload.Race,
          ),
          unitTypeOptions: mapDescriptionFieldsToSimpleList(
            'UnitTypesDescription',
            payload.UnitTypes,
          ),
          countyOptions: mapDescriptionFieldsToSimpleList(
            'Countyname',
            payload.County,
          ),
          assistanceTypeOptions: mapDescriptionFieldsToSimpleList(
            'AssistanceTypeDescription',
            payload.AssistanceType,
          ),
          politicalPartyOptions: payload.PoliticalParty,
          voterDeclaration: payload.Text_OVRApplnDeclaration.Text,
          assistanceDeclaration:
            payload.Text_OVRApplnAssistanceDeclaration.Text,
        },
      }
    case RECEIVE_PA_API_FORM_SUBMIT:
      return {
        ...state,
        error: null,
        externalApiCallId: action.payload,
      }
    case ERROR_PA_API_FORM_SUBMIT:
      return {
        ...state,
        error: action.payload,
      }
    case POLL_PA_API:
      return {
        ...state,
        pollCount: state.pollCount + 1,
      }
    case RECEIVE_PA_API_POLLING:
      return {
        ...state,
        regRequestStatus: action.payload['status'],
        regRequestBody: action.payload['response'],
      }
    case SAVE_PA_API_FORM_LOCALLY:
      return {
        ...state,
        paApiForm: action.formData,
      }
    default:
      return state
  }
}

export default registrationReducer
